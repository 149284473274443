// file: edit-company-controller.js

function EditCompanyController (company, $mdDialog, $mdToast, $rootScope) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.company = company;

    // controller functions ====================================================
    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.saveCompany = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			// TODO: save new company
            console.log('save company');

            $mdDialog.hide();
        }
    };

    self.deleteCompany = function(ev) {
        var confirm = $mdDialog.confirm()
            .title('Would you like to delete this company?')
            .content('This action is irreversible.')
            .ariaLabel('Delete')
            .targetEvent(ev)
            .ok('Yes')
            .cancel('No');

        $mdDialog.show(confirm).then(function() {
            // TODO: need delete function here
            console.log('Delete');
        }, function() {
            // TODO: need keep function here
            console.log('Keep');
        });
    };
}
