// file: add-team-member-controller.js

function AddTeamMemberController ($mdDialog, $mdToast, $rootScope, $cookies, $http, $timeout) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // Load all Teams from the given company								
	$rootScope.reloadTeams = function(companyModuleId, $rootScope, $http) {				
		$rootScope.dbSearchDirect(companyModuleId, 'Team', 100, 1,
			function(data) {												
				self.teams = new Array();
				
				angular.forEach(data.RowList, function (row, key) {
					var moduleName = $rootScope.dbReadRowFieldValue('Name', row, data.FieldSchemaList);
					var displayName = $rootScope.dbReadRowFieldValue('DisplayName', row, data.FieldSchemaList);

					var team = angular.fromJson('{"id":"","name":""}');			

					team.id = moduleName;
					team.name = displayName;

					self.teams.push(team);													
				});
			},
			function() {
				// show error
			},
			$http);
	};
	
	var companyModuleId = $cookies.get('CompanyModuleID');
	var teamModuleId = $cookies.get('ModuleID');
	   
    // controller functions ====================================================
    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.addTeamMember = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			// Create a new team member and send an invite
			// Step 1. Add the team member to the company
			// Step 1.1	Ensure a traxion account.
			// Pull a new row def from the database.
			$rootScope.dbNewDirect('master', 'sysLogin',
				function(data) {
					// Set basic user values
					$rootScope.dbWriteFieldValue('Username', data.FieldGroupList, self.teamMember.email);
					$rootScope.dbWriteFieldValue('DefaultDatabaseGuid', data.FieldGroupList, $cookies.get('ModuleID'));
					$rootScope.dbWriteFieldValue('FirstName', data.FieldGroupList, self.teamMember.fname);
					$rootScope.dbWriteFieldValue('LastName', data.FieldGroupList, self.teamMember.lname);
					
					// Write the new row to the database.
					$rootScope.dbSaveDirect('master', 'sysLogin', data,
								function(sysLoginData) {
												
									var sysLoginId = sysLoginData.PrimaryKey;
									var sysDatabaseId = $cookies.get('CompanysysDatabaseID');
																											
									$rootScope.dbNewDirect('master', 'sysLoginDatabase',
										function(data) {
											// Set basic user values
											$rootScope.dbWriteFieldValue('sysLoginID', data.FieldGroupList, sysLoginId);
											$rootScope.dbWriteFieldValue('sysDatabaseID', data.FieldGroupList, sysDatabaseId);
																
											// Write the new row to the database.
											$rootScope.dbSaveDirect('master', 'sysLoginDatabase', data,
												function() {
													// Ensure '{company module}'.User entry based on sysLoginID
													var companyModuleId = $cookies.get('CompanyModuleID');
													
													$rootScope.dbNewDirect(companyModuleId, 'User',
														function(data) {
															// Set basic user values
															$rootScope.dbWriteFieldValue('sysLoginID', data.FieldGroupList, sysLoginId);																															
															// Write the new row to the database.
															$rootScope.dbSaveDirect(companyModuleId, 'User', data,
																function(newUserData) {
																	var userId = newUserData.PrimaryKey;
																	$rootScope.sendInvite(companyModuleId, userId);
																	
																	// Reload teams members
																	$mdDialog.hide();
																	$rootScope.reloadCompanyUsers(companyModuleId, $rootScope, $http, $cookies, $rootScope.teamController);																	
																},
																function() {
																	// Do nothing on fail.
																}, $http);
													},
												function(data, status, url){
												}, $http);																								
											},
											function() {
												// Do nothing on fail.
											}, $http);
										},
										function(data, status, url){
									}, $http);										
								},
								function() {
									// Do nothing on fail.
								}, $http);
				},
				function(data, status, url){
				}, $http);			
        }
    };
}
