// file: add-issues-controller.js

function AddIssueController (issueType, $mdDialog, $mdToast, $rootScope, $cookies, $http) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.teamMembers = angular.fromJson($cookies.get('teamMembers'));

    self.intervals = ['Weekly', 'Quarterly'];

	self.issue = new Object();
	self.issue.interval = issueType;

    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.saveItem = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			// Pull a new row def from the database.
			$rootScope.dbNewDirect($cookies.get("ModuleID"), 'Issue',
				function(data) {
					// fill in values
					$rootScope.dbWriteFieldValue('Name', data.FieldGroupList, self.issue.description);
					// $rootScope.dbWriteFieldValue('Priority', data.FieldGroupList, self.issue.priority);
					$rootScope.dbWriteFieldValue('TimeFrame', data.FieldGroupList, self.issue.interval);

					// Write the new row to the database.
					$rootScope.dbSaveDirect($cookies.get("ModuleID"), 'Issue', data,
								function() {
									$mdDialog.cancel();	// Should this be something else?
								},
								function() {
								}, $http);
				},
				function(data, status, url){
				}, $http);
        }
    };
}
