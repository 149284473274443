// file: app.js

(function () {

    'use strict';

    angular.module('traxion', ['ngAnimate', 'ngCookies', 'ngRoute', 'ngMaterial', 'md.data.table', 'chart.js', 'ui.sortable'])
        .config(['$routeProvider', '$locationProvider', '$mdThemingProvider', 'ChartJsProvider',
            function ($routeProvider, $locationProvider, $mdThemingProvider, ChartJsProvider) {

				 /* ------------------- ROUTE PROVIDER */
				$routeProvider
                    .when('/', {
                        redirectTo: '/authentication'
                    })
					.when('/authentication', {
						title: 'Authentication',
                        templateUrl: '../views/authentication.html',
                        controller: 'AuthenticationController as authCtrl'
                     })
					.when('/dashboard', {
						title: 'Dashboard',
						templateUrl: '../views/dashboard.html',
						controller: 'DashboardController as boardCtrl'
					})
					.when('/issues', {
						title: 'Issues',
						templateUrl: '../views/issues.html',
						controller: 'IssuesController as issuesCtrl'
					})
					.when('/todos', {
						title: 'ToDos',
						templateUrl: '../views/todos.html',
						controller: 'TodosController as todosCtrl'
					})
					.when('/rocks', {
						title: 'Rocks',
						templateUrl: '../views/rocks.html',
						controller: 'RocksController as rocksCtrl'
					})
					.when('/scorecards', {
						title: 'Scorecards',
						templateUrl: '../views/scorecards.html',
						controller: 'ScorecardsController as scoreCtrl'
					})
                    .when('/teams', {
                        title: 'Manage Teams',
                        templateUrl: '../views/teams.html',
                        controller: 'TeamsController as teamsCtrl'
                    })
                    .when('/team', {
                        title: 'Manage People',
                        templateUrl: '../views/team.html',
                        controller: 'TeamController as teamCtrl'
                    })
                    .when('/settings', {
                        title: 'Personal Account',
                        templateUrl: '../views/settings.html',
                        controller: 'SettingsController as setCtrl'
                    })
                    .when('/companies', {
                        title: 'Companies',
                        templateUrl: '../views/companies.html',
                        controller: 'CompaniesController as compCtrl'
                    })
					.when('/404', {
						title: '404',
						templateUrl: '../views/four0four.html',
						controller: 'Four0FourController as FourCtrl'
					})
					.otherwise({
						redirectTo: '/404'
					});
                /* ------------------- (end) ROUTE PROVIDER */

                $locationProvider
                    .html5Mode({
                        enabled: true,
                        requireBase: false
                    })
                    .hashPrefix('');

                var primaryColorMap = $mdThemingProvider.extendPalette('orange', {
                    '500': 'F6822C'
                });
                $mdThemingProvider.definePalette('primaryColor', primaryColorMap);

                $mdThemingProvider.theme('default')
                    .primaryPalette('primaryColor')
                    .accentPalette('blue-grey');

                ChartJsProvider.setOptions({
                    colours: ['#F6822C', '#607D8B'],
                    responsive: true,
                    maintainAspectRatio: false
                });

                // Configure all line charts
                ChartJsProvider.setOptions('Line', {
                    // datasetFill: false,
                    // responsive: true
                });

            }])

        // change Page Title based on the routers
        .run(['$location', '$rootScope',
            function($location, $rootScope) {
                var root = $rootScope;

                root.$on('$routeChangeSuccess', function (event, current, previous) {
                    var root = $rootScope;

					if (current.$$route != null) {
						root.title = current.$$route.title;
					}
				});
            }]);

})();
