// file: companies-controller.js

(function() {

    'use strict';

    angular.module('traxion')
        .controller('CompaniesController', ['$timeout', '$cookies', '$mdDialog', '$rootScope',
            function($timeout, $cookies, $mdDialog, $rootScope) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;
				
                // controller variables ========================================                
                // for easy refreshing
                self.companies = angular.fromJson($cookies.get('companies'));
				
                self.addCompany = function(ev) {
                    $mdDialog.show({
                        controller: AddCompanyController,
                        controllerAs: 'addCompanyCtrl',
                        templateUrl: '../views/add-company.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        $timeout(function(){  self.companies = angular.fromJson($cookies.get('companies')); }, 2000);
                    }, function() {
                        $timeout(function(){  self.companies = angular.fromJson($cookies.get('companies')); }, 2000);
                    });
                };

                self.editCompany = function(selectedCompany, ev) {
                    $mdDialog.show({
                        controller: EditCompanyController,
                        controllerAs: 'editCompanyCtrl',
                        templateUrl: '../views/edit-company.tmpl.html',
                        locals: {
                            company: selectedCompany
                        },
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        $timeout(function(){  self.companies = angular.fromJson($cookies.get('companies')); }, 2000);
                    }, function() {
						$timeout(function(){  self.companies = angular.fromJson($cookies.get('companies')); }, 2000);
                    });
                };

            }]);

})();
