// file: add-todo-controller.js

function AddRockController ($mdDialog, $mdToast, $rootScope, $cookies, $http) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;
    var date = new Date();
    date.setDate(date.getDate() + 7);

    // controller variables ====================================================
    self.teamMembers = angular.fromJson($cookies.get('teamMembers'));
    self.rock = {};
    self.rock.dueBy = date;

    self.statuses = [
        {
            label: OFF_TRACK,
            value: 0
        },
        {
            label: ON_TRACK,
            value: 1
        },
        {
            label: COMPLETE,
            value: 2
        }
    ];

    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.saveItem = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
            // Pull a new row def from the database.
			$rootScope.dbNewDirect($cookies.get("ModuleID"), 'Rock',
				function(data) {
					// fill in values
					$rootScope.dbWriteFieldValue('Name', data.FieldGroupList, self.rock.task);
					$rootScope.dbWriteFieldValue('Status', data.FieldGroupList, self.rock.priority.value);
					$rootScope.dbWriteFieldValue('DueDate', data.FieldGroupList, self.rock.dueBy);
					$rootScope.dbWriteFieldValue('AssignedTo', data.FieldGroupList, self.rock.owner);

					// Write the new row to the database.
					$rootScope.dbSaveDirect($cookies.get("ModuleID"), 'Rock', data,
								function() {
									$mdDialog.cancel();	// Should this be something else?
								},
								function() {
								}, $http);
				},
				function(data, status, url){
				}, $http);
        }
    };
}
