// file: sidebar-controller.js

(function() {

    'use strict';

    angular.module('traxion')
        .controller('SidebarController', ['$cookies', '$http', '$location', '$mdSidenav', '$mdDialog', '$rootScope',
            function($cookies, $http, $location, $mdSidenav, $mdDialog, $rootScope) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                // controller variables ========================================
                self.teams = angular.fromJson($cookies.get('teams'));

                self.sidenav = [
                    {
                        title: 'Dashboard',
                        path: '/dashboard'
                    },
                    {
                        title: 'Issues',
                        path: '/issues'
                    },
                    {
                        title: 'ToDos',
                        path: '/todos'
                    },
                    {
                        title: 'Rocks',
                        path: '/rocks'
                    },
                    {
                        title: 'Scorecards',
                        path: '/scorecards'
                    }
                ];

				self.selectedTeam = $cookies.get('TeamName');

                // controller functions ========================================
                self.closeSidebar = function() {
                    $mdSidenav('left').close();
                };

                self.viewAllTeams = function(ev) {
                    $mdDialog.show({
                        controller: ViewTeamsController,
                        controllerAs: 'viewTeamsCtrl',
                        templateUrl: '../views/view-teams.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function(answer) {
                        self.selectedTeam = $cookies.get('TeamName');
						self.teams = angular.fromJson($cookies.get('teams'));
						
                    }, function() {
                        self.selectedTeam = $cookies.get('TeamName');
						self.teams = angular.fromJson($cookies.get('teams'));						
                    });
                };

				self.switchTeam = function(newTeam) {

					$rootScope.loadTeam($cookies, $rootScope, $http, newTeam.id);

                    self.selectedTeam = $cookies.get('TeamName');
					self.teams = angular.fromJson($cookies.get('teams'));
					
					$mdDialog.cancel();	
					
					$location.path('/dashboard');
				};

            }]);

})();
