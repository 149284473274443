// input-handler-directive.js

(function() {
    'use strict';

    angular.module('traxion')
        .directive('inputHandler', ['$timeout',
            function($timeout) {
                var initialValue;

                return {
                    restrict: 'AE',
                    require: 'ngModel',
                    link: function (scope, element, attrs, ctrl) {
                        element.bind('focus', function (event) {
                            initialValue = ctrl.$modelValue;
                        });

                        element.bind('keydown', function (event) {
                            if (event.keyCode === 9) {
                                if (scope.clientRetainerEdit) {
                                    var nextElement = element.parent().parent().parent().next();
                                    var clickHandler = nextElement.find('.click-handler');
                                    var inputElement = clickHandler.find('input')[0];
                                    clickHandler.triggerHandler('click');
                                    $timeout(function() {
                                        inputElement.focus();
                                    }, 10);
                                }
                            }
                        });

                        element.bind('keyup', function (event) {
                            if (event.keyCode === 13) {
                                ctrl.$commitViewValue();
                                event.target.blur();
                                if (scope.clientRetainerEdit === false) {
                                    var nextRow = element.parent().parent().parent().parent().next();
                                    var index;
                                    if (nextRow.hasClass('row-header')) {
                                        index = scope.$parent.$parent.$index;
                                    } else {
                                        index = scope.$parent.$index - 5;
                                    }
                                    var children = nextRow.children();
                                    var clickHandler = angular.element(children[index]).find('.click-handler');
                                    var inputElement = clickHandler.find('input')[0];
                                    clickHandler.triggerHandler('click');
                                    $timeout(function() {
                                        inputElement.focus();
                                    }, 10);
                                }
                            } else if (event.keyCode === 27) {
                                ctrl.$rollbackViewValue();
                                if (scope.issue) {
                                    scope.issue.name = initialValue;
                                } else if (scope.todo) {
                                    scope.todo.task = initialValue;
                                } else if (scope.rock) {
                                    scope.rock.rock = initialValue;
                                } else if (scope.clientRetainerEdit) {
                                    scope.$parent.$parent.task[scope.$parent.$index] = initialValue;
                                }
                                event.target.blur();
                            }
                        });
                    }
                }
            }]);
})();
