// display-handler-directive.js

(function() {
    'use strict';

    angular.module('traxion')
        .directive('displayHandler', [
            function() {
                return {
                    restrict: 'AE',
                    link: function (scope, element, attrs) {
                        element.bind('click', function (event) {
                            if (element.find('[input-handler]').length === 0) {
                                if (element.find('md-select').length !== 0) {
                                    element.find('md-select').focus();
                                }
                            } else {
                                element.find('[input-handler]').focus();
                            }
                        });
                    }
                }
            }]);
})();
