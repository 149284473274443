// file: edit-account-controller.js

function EditAccountController ($cookies, $mdDialog, $mdToast, $rootScope, $http) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.companies = angular.fromJson($cookies.get('companies'));
    self.teams = angular.fromJson($cookies.get('teams'));

    // controller functions ====================================================
    self.cancel = function() {
        $mdDialog.cancel();
    };

	self.readCurrentSettings = function(callback) {
		// Read the account information.
		$rootScope.dbReadDirect('master', 'sysLogin', '{sysLoginID}',
			function(userSettingsData) {
				callback(userSettingsData);
			},
			function () {
			}, $http);
	};
	
    self.saveName = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			self.readCurrentSettings(function(userSettingsData) {
				// Update the First name and Last name
				$rootScope.dbWriteFieldValue('FirstName', userSettingsData.FieldGroupList, self.user.firstName);
				$rootScope.dbWriteFieldValue('LastName', userSettingsData.FieldGroupList, self.user.lastName);
				
				// Save the account. Important: Passwords are hashed and never actually stored.
				$rootScope.dbSaveDirect('master', 'sysLogin', userSettingsData,
					function() {
						// On Success
						$mdDialog.hide();
					},
					function() {
						// On Failure
					},
					$http);
			});      
        }
    };

    self.saveEmail = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			self.readCurrentSettings(function(userSettingsData) {
				// Update the email / Username
				$rootScope.dbWriteFieldValue('Username', userSettingsData.FieldGroupList, self.user.email);
				
				// Save the account. Important: Passwords are hashed and never actually stored.
				$rootScope.dbSaveDirect('master', 'sysLogin', userSettingsData,
					function() {
						// On Success
						$mdDialog.hide();
					},
					function() {
						// On Failure
					},
					$http);
			});      
        }
    };

    self.savePassword = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {			
			self.readCurrentSettings(function(userSettingsData) {
				// Update the password
				//$rootScope.dbWriteFieldValue('Password', userSettingsData.FieldGroupList, editAccountCtrl.user.password);
				
				// Save the account. Important: Passwords are hashed and never actually stored.
				$rootScope.dbSaveDirect('master', 'sysLogin', userSettingsData,
					function() {
						// On Success
						$mdDialog.hide();
					},
					function() {
						// On Failure
					},
					$http);
			});            
        }
    };
}
