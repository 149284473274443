// file: dashboard-controller.js

(function() {

    'use strict';

    angular.module('traxion')
        .controller('DashboardController', ['$rootScope',
            function($rootScope) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                // controller variables ========================================
                self.line = {};
                self.line.labels = [
                    'Aug 3, 2015', 'Aug 10, 2015', 'Aug 17, 2015',
                    'Aug 24, 2015', 'Aug 31, 2015', 'Sep 7, 2015',
                    'Sep 14, 2015', 'Sep 21, 2015', 'Sep 28, 2015',
                    'Oct 5, 2015', 'Oct 12, 2015', 'Oct 19, 2015'
                ];
                self.line.series = ['Achieved', 'Goal'];
                self.line.data = [
                    [
                        10000, 11000, 9000, 8500, 12000, 11500,
                        11250, 10050, 10450, 9850, 12050, 10200
                    ],
                    [
                        10000, 10000, 10000, 9000, 11000, 11500,
                        12000, 11000, 10000, 10000, 10000, 10000,
                    ]
                ];

                self.bar = {};
                self.bar.labels = [
                    'Aug 3, 2015', 'Aug 10, 2015', 'Aug 17, 2015',
                    'Aug 24, 2015', 'Aug 31, 2015', 'Sep 7, 2015',
                    'Sep 14, 2015', 'Sep 21, 2015', 'Sep 28, 2015',
                    'Oct 5, 2015', 'Oct 12, 2015', 'Oct 19, 2015'
                ];
                self.bar.series = ['Achieved', 'Goal'];
                self.bar.data = [
                    [
                        10, 13, 14, 17, 20, 19,
                        22, 23, 25, 26, 28, 30
                    ],
                    [
                        12, 14, 16, 18, 20, 18,
                        20, 22, 24, 26, 26, 26
                    ]
                ];

                self.radar = {};
                self.radar.labels = [
                    'Wait Time', 'Proposals Sent', 'New Clients'
                ];
                self.radar.series = ['Achieved', 'Goal'];
                self.radar.data = [
                    [
                        1, 4, 2
                    ],
                    [
                        2, 3, 2
                    ]
                ];

            }]);

})();
