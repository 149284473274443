// file: view-teams-controller.js

function ViewTeamsController ($mdDialog, $rootScope, $cookies, $http, $location) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.teams = angular.fromJson($cookies.get('teams'));

    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.loadTeam = function(team) {
        // ability to switch between teams
        $rootScope.loadTeam($cookies, $rootScope, $http, team.id);
		$mdDialog.cancel();			
		$location.path('/dashboard');
    };
}
