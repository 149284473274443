// file: add-team-controller.js

function AddTeamController ($mdDialog, $mdToast, $rootScope, $cookies, $http) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.addTeam = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			var companyModuleId = $cookies.get('CompanyModuleID');
			
			$rootScope.dbNewDirect(companyModuleId, 'Team',
				function(data) {
					// Set basic user values
					$rootScope.dbWriteFieldValue('DisplayName', data.FieldGroupList, self.team.name);																															
					$rootScope.dbWriteFieldValue('Name', data.FieldGroupList, companyModuleId + '_' + self.team.name);																															
					// Write the new row to the database.
					$rootScope.dbSaveDirect(companyModuleId, 'Team', data,
						function() {
							// Reload teams members
							$mdDialog.hide();
							$rootScope.reloadCompanyTeams(companyModuleId, $rootScope, $http, $rootScope.teamsController);				
							$rootScope.reAuth();	// Reload teams for which this user has access.
						},
						function() {
							// Do nothing on fail.
						}, $http);    
				},
				function() {
					
				}, $http);    
        }
    };
}
