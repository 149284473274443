// file: edit-team-member-controller.js

function EditTeamMemberController (teamMember, $mdDialog, $mdToast, $rootScope, $cookies, $http, $timeout) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.teamMember = teamMember;

    // Load Teams
	$rootScope.reloadTeams = function(companyModuleId, $rootScope, $http) {
		$rootScope.dbSearchDirect(companyModuleId, 'Team', 100, 1,
			function(data) {
				self.teams = new Array();

				angular.forEach(data.RowList, function (row, key) {
					var teamId = $rootScope.dbReadRowFieldValue('TeamID', row, data.FieldSchemaList);
					var moduleName = $rootScope.dbReadRowFieldValue('Name', row, data.FieldSchemaList);
					var displayName = $rootScope.dbReadRowFieldValue('DisplayName', row, data.FieldSchemaList);
                    var teams = $rootScope.dbReadRowFieldValue('Teams', row, data.FieldSchemaList);

					var team = angular.fromJson('{"id":"","name":""}');

					team.id = moduleName;
					team.name = displayName;
					team.teamId = teamId;

					self.teams.push(team);
				});
				
				// Load teams for each team member.
				$rootScope.dbSearchDirectFiltered(companyModuleId, 'UserTeam', 'UserID', teamMember.id, 100, 1, 
					function(userTeamData) {
						self.teamMember.teams = new Array();

						angular.forEach(self.teams, function (team, teamKey) {

							var teamMemberTeam = team;
							teamMemberTeam.access = false;
						
							angular.forEach(userTeamData.RowList, function (row, key) {
								var userTeamId = $rootScope.dbReadRowFieldValue('UserTeamID', row, userTeamData.FieldSchemaList);
								var teamId = $rootScope.dbReadRowFieldValue('TeamID', row, userTeamData.FieldSchemaList);
								
								if (teamId == teamMemberTeam.teamId) {
									teamMemberTeam.access = true;	
									teamMemberTeam.userTeamId = userTeamId;
								}
							});
							
							self.teamMember.teams.push(teamMemberTeam);
						});				
					},
					function() {
							
					},
					$http);				
			},
			function() {
				// show error
			},
			$http);
	};

	var companyModuleId = $cookies.get('CompanyModuleID');

	$rootScope.reloadTeams(companyModuleId, $rootScope, $http);

    // controller functions ====================================================
    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.saveTeamMember = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			// Loop through all self.teamMember.teams
			angular.forEach(self.teamMember.teams, function (team, key) {
				if (team.access) {
					// Add / Ensure Access
					if (isNullOrWhiteSpace(team.userTeamId)) {						
						$rootScope.dbNewDirect(companyModuleId, 'UserTeam',
							function(data) {
								$rootScope.dbWriteFieldValue('UserID', data.FieldGroupList, self.teamMember.id);
								$rootScope.dbWriteFieldValue('TeamID', data.FieldGroupList, team.teamId);

								// Write the new row to the database.
								$rootScope.dbSaveDirect(companyModuleId, 'UserTeam', data,
											function() {
												// Success
											},
											function() {
												// Fail
											}, $http);
							},
							function(data, status, url){
							}, $http);
						
						///////
					}					
				} else {
					// Delete Access
					$rootScope.dbDeleteDirect(companyModuleId, 'UserTeam',
						team.userTeamId,
						function() {
							
						},
						function() {
							// Show error
						}
						, $http);
				}
			});
			
			// Reload after saves.
			$timeout(function() { $rootScope.reloadTeamMembers($rootScope, $http, $cookies, $timeout); }, 2000);
			
            $mdDialog.hide();
        }
    };

    self.deleteTeamMember = function(ev) {
        var confirm = $mdDialog.confirm()
            .title('Would you like to delete this team member?')
            .content('This action is irreversible.')
            .ariaLabel('Delete')
            .targetEvent(ev)
            .ok('Yes')
            .cancel('No');

        $mdDialog.show(confirm).then(function() {
			var companyModuleId = $cookies.get('CompanyModuleID');
			var userId = self.teamMember.id;

			// Read the TeamMember to get the sysLoginId
			$rootScope.dbReadDirect(companyModuleId,
									'User',
									userId,
									function(userData) {
										var sysLoginId = $rootScope.dbReadFieldValue('sysLoginID', userData.FieldGroupList);

										// Remove user {company module}.User entry
										$rootScope.dbDeleteDirect(companyModuleId, 'User',
																	userId,
																	function() {
																		// Success
																		$rootScope.reloadTeamMembers($rootScope, $http, $cookies, $timeout);
																	},
																	function() {
																		// Show error
																	}
																	, $http);

										// Remove the Company Database
										removeFromCompanyDatabase(sysLoginId, companyModuleId, $rootScope, $http);

										// Loop through each team and remove each team database.
										removeFromTeamDatabases(sysLoginId, self.teams, $rootScope, $http);
									},
									function() {
										// Error
									},
									$http);
        }, function() {
            // TODO: need keep function here
            console.log('Keep');
        });
    };
}

// Removes the user's ability for the given user to access the team database
function removeFromCompanyDatabase(sysLoginId, companyModuleId, $rootScope, $http) {

	removeUserFromDatabase(sysLoginId, companyModuleId, $rootScope, $http);

}

// Removes the user's ability to access any individual team database.
function removeFromTeamDatabases(sysLoginId, teams, $rootScope, $http) {

	angular.forEach(teams, function (team, key) {
		removeUserFromDatabase(sysLoginId, team.id, $rootScope, $http)
	});
}

function removeUserFromDatabase(sysLoginId, moduleId, $rootScope, $http) {

	// Locate the sysDatabaseID for the given moduleID
	$rootScope.dbSearchDirectFiltered('master', 'sysDatabase', 'Name', moduleId, 1, 1,
		function(data) {

			angular.forEach(data.RowList, function (row, key) {
				var sysDatabaseID = $rootScope.dbReadRowFieldValue('sysDatabaseID', row, data.FieldSchemaList);

				// search for the sysLogin database where the sysDatabaseID matches and the sysLoginID matches.

				$rootScope.dbSearchDirectFilteredAnd('master', 'sysLoginDatabase', 'sysLoginID', sysLoginId, 'sysDatabaseID', sysDatabaseID, 1, 1,
					function(sysLoginDatabaseData) {

						angular.forEach(sysLoginDatabaseData.RowList, function (row, key) {
							var sysLoginDatabaseId = $rootScope.dbReadRowFieldValue('sysLoginDatabaseID', row, sysLoginDatabaseData.FieldSchemaList);
							// Remove master.sysLoginDatabase entry
							$rootScope.dbDeleteDirect('master', 'sysLoginDatabase',
														sysLoginDatabaseId,
														function() {
															// Success
														},
														function() {
															// Show error
														}
														, $http);
						});
					},
					function() {
						// show error
					},
					$http);
			});
		},
		function() {
			// show error
		},
		$http);
}
