// file: add-company-controller.js

function AddCompanyController ($mdDialog, $mdToast, $rootScope, $http, $cookies, $location, $timeout) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.cancel = function() {
        $mdDialog.cancel();
    };
	
	self.addCurrentUserToCompanyUserAndTeamMemberList = function(newCompanyDataResult, newTeamDataResult, successCallback) {
		
		//-------------------------------
		// Read the newly created company
		// Read the TeamMember to get the sysLoginId
		$rootScope.dbReadDirect('master',
								'sysDatabase',
								newCompanyDataResult.PrimaryKey,
								function(companyData) {
									//$$$$$$$$$$$$$$$$$$$$
									// Read the newly created team.
									$rootScope.dbReadDirect('master',
										'sysDatabase',
										newTeamDataResult.PrimaryKey,
										function(teamData) {
											/////////////////////////////////
											var companyModuleId = $rootScope.dbReadFieldValue('Name', companyData.FieldGroupList);
											var teamModuleId = $rootScope.dbReadFieldValue('Name', teamData.FieldGroupList);
											// Add User to Company and Team
											$rootScope.addCurrentUserToCompanyAndTeam(companyModuleId, teamModuleId, self.team.name, $cookies, $rootScope, $http, $location, $timeout, function() {
												successCallback();												
											});														
											/////////////////////////////////
										},
										function() {
											// Error
										},
										$http);		
									//$$$$$$$$$$$$$$$$$$$$
								},
								function() {
									// Error
								},
								$http);
		//-------------------------------											
											
	};

    self.addCompany = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			// Create sysDatabase entry
			// BEGIN CREATE COMPNAY ////////////////////////////
			$rootScope.dbNewDirect('master', 'sysDatabase',
				function(newCompanyData) {
					// Set basic user values
					var randomNumber = Math.floor((Math.random() * 10000) + 1);
					
					$rootScope.dbWriteFieldValue('Name', newCompanyData.FieldGroupList, self.company.name + randomNumber);	// Allow for additional uniqueness
					$rootScope.dbWriteFieldValue('DisplayName', newCompanyData.FieldGroupList, self.company.name);
					$rootScope.dbWriteFieldValue('Type', newCompanyData.FieldGroupList, 'Company');
					// Create a new company database.
					$rootScope.dbSaveDirect('master', 'sysDatabase', newCompanyData,
						function(newCompanyDataResult) {
							////////////////////////////////////
							// BEGIN CREATE TEAM ///////////////							
							$rootScope.dbNewDirect('master', 'sysDatabase',
								function(newTeamData) {
									// Create Default Team (same format at new company)
									$rootScope.dbWriteFieldValue('Name', newTeamData.FieldGroupList, newCompanyDataResult.PrimaryKey + '_' + self.team.name);																															
									$rootScope.dbWriteFieldValue('DisplayName', newTeamData.FieldGroupList, self.team.name);							
									$rootScope.dbWriteFieldValue('Type', newTeamData.FieldGroupList, 'Team');
									$rootScope.dbWriteFieldValue('ParentSysDatabaseID', newTeamData.FieldGroupList, newCompanyDataResult.PrimaryKey);							
									// Write the new row to the database.
									$rootScope.dbSaveDirect('master', 'sysDatabase', newTeamData,
										function(newTeamDataResult) {
											self.addCurrentUserToCompanyUserAndTeamMemberList(newCompanyDataResult, newTeamDataResult, function() {
												$rootScope.reAuth();	// Reload teams for which this user has access.
												$mdDialog.hide();
											});
										},
										function() {
											// Do nothing on fail.
										}, $http);											
							},
							function() {

							}, $http);								
							// END CREATE TEAM //////////////////							
							////////////////////////////////////
						},
						function() {
							// Do nothing on fail.
						}, $http);
						
				},
				function() {

				}, $http);
			// END CREATE COMPANY ////////////////////////////	
        }
    };
}
