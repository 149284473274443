// file: todos-controller.js

(function() {
    'use strict';

    angular.module('traxion')
        .controller('TodosController', ['$cookies', '$rootScope', '$q', '$timeout', '$http', '$mdDialog',
            function($cookies, $rootScope, $q, $timeout, $http, $mdDialog) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                // controller variables ========================================
                self.owner = $cookies.get('currentUser');
                self.taskOwners = angular.fromJson($cookies.get('teamMembers'));

                self.statuses = [
                    {
                        label: OFF_TRACK,
                        value: 0
                    },
                    {
                        label: ON_TRACK,
                        value: 1
                    },
                    {
                        label: COMPLETE,
                        value: 2
                    },
                ];

                self.columns = [
                    {
                        name: 'Task',
                        hide: false
                    },
                    {
                        name: 'Status',
                        hide: false
                    },
                    {
                        name: 'Owner',
                        hide: true
                    },
                    {
                        name: 'Due By',
                        hide: true
                    }
                ];

				// Load issues from the API
				dbService($rootScope, $http, $timeout);

				reloadToDosFromDb(self, $rootScope, $http, $cookies);

				self.saveField = function(todo, primaryFieldID, primaryFieldValue, fieldIdToUpdate, newValue) {
					$rootScope.dbSaveFieldDirect($cookies.get("ModuleID"), 'ToDo',
						primaryFieldID,
						primaryFieldValue,
						fieldIdToUpdate,
						newValue,
						function() {
							// Do nothing on sucess
						},
						function() {
							// Show error
						}
						, $http);


					switch (fieldIdToUpdate)
					{
						case 'Status':
							switch (newValue)
							{
								case '0':
									todo.complete = false;
									todo.status.label = OFF_TRACK;
									break;

								case '1':
									todo.complete = false;
									todo.status.label = ON_TRACK;
									break;

								case '2':
									todo.status.label = COMPLETE;
									todo.complete = true;
									break;
							}
							break;
					}
				};

				self.saveToDo = function(todo, fieldIdToUpdate, newValue) {
					self.saveField(todo, todo.pkfieldid, todo.pkfieldval, fieldIdToUpdate, newValue);
				}

				self.onSelectChange = function(todo) {

					if (todo.complete) {
						todo.status.value = 2;
						todo.status.label = COMPLETE;
					}
					else {
						todo.status.value = 0;
						todo.status.label = OFF_TRACK;
					}

					self.saveToDo(todo, 'Status', todo.status.value);

                };

                self.todoOwnerFilter = function(todo) {
                    return todo.owner === self.owner;
                };

                self.addTodo = function(ev) {
                    $mdDialog.show({
                        controller: AddTodoController,
                        controllerAs: 'addTodoCtrl',
                        templateUrl: '../views/add-todo.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function(answer) {
                        // $scope.status = 'You said the information was "' + answer + '".';
                    }, function() {
                        reloadToDosFromDb(self, $rootScope, $http, $cookies);
                    });
                };

                self.deleteTodo = function(todo, ev) {
                    var confirm = $mdDialog.confirm()
                        .title('Would you like to delete this todo?')
                        .content('This action is irreversible.')
                        .ariaLabel('Delete')
                        .targetEvent(ev)
                        .ok('Yes')
                        .cancel('No');

                    $mdDialog.show(confirm).then(function() {
                        $rootScope.dbDeleteDirect($cookies.get("ModuleID"), 'ToDo',
														todo.pkfieldval,
														function() {
															reloadToDosFromDb(self, $rootScope, $http, $cookies);
														},
														function() {
															// Show error
														}
														, $http);
                    }, function() {
                        // TODO: need keep function here
                        console.log('Keep');
                    });
                };

            }]);
})();

function reloadToDosFromDb(self, $rootScope, $http, $cookies) {

	$rootScope.dbSearchDirect($cookies.get("ModuleID"), 'ToDo', 100, 1,
											function(data) {
												reloadToDoGrid(self, $rootScope, data);
											},
											function() {
												// show error
											},
											$http);
}

function reloadToDoGrid(self, $rootScope, data) {

	// Convert data into issues
	var taskJson = '{"count": "0","data": []}';

	var tasksData = angular.fromJson(taskJson);
	tasksData.count = data.TotalRows;

	// foreach row
	angular.forEach(data.RowList, function (row, key) {
		var primaryFieldID = data.PrimaryFieldID;
		var primaryFieldValue = $rootScope.dbReadRowFieldValue(data.PrimaryFieldID, row, data.FieldSchemaList);
		var taskName = $rootScope.dbReadRowFieldValue("Name", row, data.FieldSchemaList);
		var taskStatusValue = $rootScope.dbReadRowFieldValue("Status", row, data.FieldSchemaList);
		var taskDueBy = Date.parse($rootScope.dbReadRowFieldValue("DueDate", row, data.FieldSchemaList));
		var taskOwner = $rootScope.dbReadRowFieldValue("AssignedTo", row, data.FieldSchemaList);

		var itemJson = '{"pkfieldid":"","pkfieldval":"","task": "This is a simple task","status": {"label": "Off-target","value": 0},"owner": ""}';

		var task = angular.fromJson(itemJson);

		task.pkfieldid = primaryFieldID;
		task.pkfieldval = primaryFieldValue;
		task.task = taskName;
        task.status.value = taskStatusValue;
		task.complete = (taskStatusValue == 2);
		task.owner = taskOwner;

		switch (taskStatusValue)
		{
			case '0':
				task.status.label = OFF_TRACK;
                task.status.value = parseInt(0);
				break;

			case '1':
				task.status.label = ON_TRACK;
                task.status.value = parseInt(1);
				break;

			case '2':
				task.status.label = COMPLETE;
                task.status.value = parseInt(2);
				break;

			default:
				task.status.label = OFF_TRACK;
				task.status.value = parseInt(0);
				break;
		}

        if (taskDueBy) {
            task.dueBy = new Date(taskDueBy);
        }
        else {
    		task.dueBy = new Date(+new Date + 604800000);
        }

		tasksData.data.push(task);

	});

	self.tasks = tasksData;
}
