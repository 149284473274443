// file: issues-controller.js

(function() {
    'use strict';

    angular.module('traxion')
        .controller('IssuesController', ['$cookies', '$rootScope', '$q', '$timeout', '$http', '$mdDialog',
            function($cookies, $rootScope, $q, $timeout, $http, $mdDialog) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                // controller variables ========================================
                self.teamMembers = angular.fromJson($cookies.get('teamMembers'));

                self.issueType = 'Weekly';

                self.issueTypes = ['Weekly', 'Quarterly'];

                self.columns = [
                    {
                        name: 'Issue',
                        flex: 'auto',
                        hideSm: false
                    },
                    {
                        name: 'Created By',
                        flex: '25',
                        hideSm: true
                    }
                ];

				dbService($rootScope, $http, $timeout);		// Init the API
				DragSortService($rootScope, $http, $timeout)	// Load the Drag-drop API service

				reloadIssuesFromDb(self, $rootScope, $http, $cookies);

                self.sortableOptions = {
                    update: function(e, ui) {
                        if (self.issueType === 'Weekly') {
                            angular.forEach(self.issues.data.weekly, function(issue, key) {
                                issue.priority = key;
                            });
                        } else if (self.issueType === 'Quarterly') {
                            angular.forEach(self.issues.data.quarterly, function(issue, key) {
                                issue.priority = key;
                            });
                        }

						self.onDragDropComplete();
                    }
                };

				self.onDragDropComplete = function() {
					// Assumption: The drag-drop library reorders the data within self.issues
					self.issuesData.AllowDragDropReorder = true;
					self.issuesData.DefaultSortColumn = 'Priority';
					self.issuesData.DefaultSortOrder = 'Asc';

					var moduleId = $cookies.get("ModuleID");

					// Sync self.issues and self.issuesData with regard to sequence.
					if (self.issueType === 'Weekly') {
						$timeout(function() { self.syncSort(self.issues.data.weekly, self, $rootScope, moduleId, $http); }, 1000);
					} else if (self.issueType === 'Quarterly') {
						$timeout(function() { self.syncSort(self.issues.data.quarterly, self, $rootScope, moduleId, $http); }, 1000);
					}
				};

                self.onCreatedByChange = function(createdBy) {
                    console.log(createdBy);
                };


				self.addItem = function() {
					// Pull a new row def from the database.
					$rootScope.dbNewDirect($cookies.get("ModuleID"), 'Issue',
						function(data) {
							// Add any default values here
							// Write the new row to the database.
							$rootScope.dbSaveDirect($cookies.get("ModuleID"), 'Issue', data,
								function() {
									reloadIssuesFromDb(self, $rootScope, $http);
								},
								function() {
								},
								$http);
						},
						function(data, status, url){

						},
						$http);

					// Add the new row to the grid.
				};

				self.saveField = function(primaryFieldID, primaryFieldValue, fieldIdToUpdate, newValue) {
					$rootScope.dbSaveFieldDirect($cookies.get("ModuleID"), 'Issue',
    					primaryFieldID,
    					primaryFieldValue,
    					fieldIdToUpdate,
    					newValue,
    					function() {
    						// Do nothing on sucess
    					},
    					function() {
    						// Show error
    					}, $http);
				};

				self.saveIssue = function(issue, fieldIdToUpdate, newValue) {
					var apiFieldName = '';

					// Map the local field names to the API field names.
					switch (fieldIdToUpdate)
					{
						case 'name':
							apiFieldName = 'Name';
							break;

						case 'priority':
							apiFieldName = 'Priority';
							break;

                        case 'createdBy':
                            apiFieldName = 'CreatedBy';
                            break;
					}

					self.saveField(issue.pkfieldid, issue.pkfieldval, apiFieldName, newValue);
				};

                self.addIssue = function(ev) {
                    $mdDialog.show({
                        controller: AddIssueController,
                        controllerAs: 'addIssueCtrl',
                        templateUrl: '../views/add-issue.tmpl.html',
                        locals: {
                            issueType: self.issueType
                        },
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function(answer) {
                        // $scope.status = 'You said the information was "' + answer + '".';
                    }, function() {
                        // $scope.status = 'You cancelled the dialog.';
						reloadIssuesFromDb(self, $rootScope, $http, $cookies);
                    });
                };

                self.switchIssueTeam = function(issue, ev) {
                    $mdDialog.show({
                        controller: SwitchIssueTeamController,
                        controllerAs: 'switchTeamIssueCtrl',
                        templateUrl: '../views/switch-issue-team.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        locals: {
                            issue: issue
                        }
                    })
                    .then(function(answer) {
                        // $scope.status = 'You said the information was "' + answer + '".';
                    }, function() {
                        // $scope.status = 'You cancelled the dialog.';
						reloadIssuesFromDb(self, $rootScope, $http, $cookies);
                    });
                };

                self.deleteIssue = function(issue, ev) {
                    var confirm = $mdDialog.confirm()
                        .title('Would you like to delete this issue?')
                        .content('This action is irreversible.')
                        .ariaLabel('Delete')
                        .targetEvent(ev)
                        .ok('Yes')
                        .cancel('No');

                    $mdDialog.show(confirm).then(function() {
						$rootScope.dbDeleteDirect($cookies.get("ModuleID"), 'Issue',
    						issue.pkfieldval,
    						function() {
    							reloadIssuesFromDb(self, $rootScope, $http, $cookies);
    						},
    						function() {
    							// Show error
    						}
    						, $http);

                    }, function() {
                        // TODO: need keep function here
                        console.log('Keep');
                    });
                };

				self.syncSort = function(issuesData, self, $rootScope, moduleId, $http) {

					var displayOrder = 0;

					// Set the priority of each item after the drag-complete.
					angular.forEach(issuesData, function (issue, key) {
							issue.priority = displayOrder;
							displayOrder++;
					});

					// Transfer each priorty
					angular.forEach(issuesData, function (issue, key) {

						var pageIssuePk = issue.pkfieldval;

						angular.forEach(self.issuesData.RowList, function (row, key) {
							var dataIssuePk = $rootScope.dbReadRowFieldValue(self.issuesData.PrimaryFieldID, row, self.issuesData.FieldSchemaList);

							if (pageIssuePk == dataIssuePk) {
								row.PageOrder = issue.priority;
							}
						});
					});

					// Reorder the underlying data
					var newArray = new Array();

					while (self.issuesData.RowList.length > 0) {
						var minSortValueKey = readMinSortValueKey(self.issuesData);
						if (minSortValueKey == null) {
							break;
						}
						newArray.push(self.issuesData.RowList[minSortValueKey]);
						delete self.issuesData.RowList[minSortValueKey];
					}

					self.issuesData.RowList = newArray;

					// we may need to execute this via $timeout to allow the display to update.
					$rootScope.sortCompleteSaveDirect(moduleId, 'Issue', self.issuesData, function() {}, function() {}, $http);
				};

            }]);
})();

function reloadIssuesFromDb(self, $rootScope, $http, $cookies) {

	$rootScope.dbSearchDirect($cookies.get("ModuleID"), 'Issue', 100, 1,
											function(data) {
												reloadIssueGrid(self, $rootScope, data);
											},
											function() {
												// show error
											},
											$http);
}

function reloadIssueGrid(self, $rootScope, data) {
	self.issuesData = data;

	// Convert data into issues
	var issueJson = '{"count": "0","data": []}';

	var issuesData = angular.fromJson(issueJson);
	issuesData.count = data.TotalRows;

	// foreach row
	angular.forEach(data.RowList, function (row, key) {
		var primaryFieldID = data.PrimaryFieldID;
		var primaryFieldValue = $rootScope.dbReadRowFieldValue(data.PrimaryFieldID, row, data.FieldSchemaList);
		var issueName = $rootScope.dbReadRowFieldValue("Name", row, data.FieldSchemaList);
		var issuePriorityValue = $rootScope.dbReadRowFieldValue("Priority", row, data.FieldSchemaList);
		var issueCreatdBy = $rootScope.dbReadRowFieldValue("CreatedBy", row, data.FieldSchemaList);
		var issueType = $rootScope.dbReadRowFieldValue("TimeFrame", row, data.FieldSchemaList);
        var issueTeam = $rootScope.dbReadRowFieldValue("Team", row, data.FieldSchemaList);

		var itemJson = '{"pkfieldid":"","pkfieldval":"","name": "","priority": {"label": "","value": "1"},"createdBy": "","type": ""}';

		var issue = angular.fromJson(itemJson);

		issue.pkfieldid = primaryFieldID;
		issue.pkfieldval = primaryFieldValue;
		issue.name = issueName;
        issue.priority = issuePriorityValue;
		issue.createdBy = issueCreatdBy;
		issue.type = issueType;

		issuesData.data.push(issue);

	});

    self.issues = {};
    self.issues.data = {};
    self.issues.data.weekly = [];
    self.issues.data.quarterly = [];

    angular.forEach(issuesData.data, function(issue, key) {
        if (issue.type === 'Weekly') {
            self.issues.data.weekly.push(issue);
        } else if (issue.type === 'Quarterly') {
            self.issues.data.quarterly.push(issue);
        }
    });
}

function readMinSortValueKey(gridData) {

	var minSortValue = null;
	var minSortValueKey = null;

	angular.forEach(gridData.RowList, function (row, key) {

		var sortValue = row.PageOrder;

		if ((minSortValue == null) || (sortValue < minSortValue)) {
			minSortValue = sortValue;
			minSortValueKey = key;
		}

	});

	return minSortValueKey;
}
