// file: add-issues-controller.js

function AddScorecardController ($mdDialog, $mdToast, $rootScope, $cookies, $http) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.teamMembers = angular.fromJson($cookies.get('teamMembers'));

    self.intervals = ['Weekly', 'Monthly'];
    
	self.types = ['Currency','Number', 'Percent'];
	
    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.saveItem = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			// Insert the new measurable.
			$rootScope.dbNewDirect($cookies.get("ModuleID"), 'ScorecardVariable',
				function(data) {
					// Fill in all new interval data
					$rootScope.dbWriteFieldValue("Mesaure", data.FieldGroupList, self.score.description);
					$rootScope.dbWriteFieldValue("Goal", data.FieldGroupList, self.score.goal);
					$rootScope.dbWriteFieldValue("Type", data.FieldGroupList, self.score.type);
					$rootScope.dbWriteFieldValue("Owner", data.FieldGroupList, self.score.owner);
					$rootScope.dbWriteFieldValue("Interval", data.FieldGroupList, self.issue.interval);
					// Create a new interval in the database.
					$rootScope.dbSaveDirect($cookies.get("ModuleID"), 'ScorecardVariable', data,
						function() {
							// reload the grid
							$rootScope.reloadScorecard();
						},
						function() {
						}, $http
					);
				},
				function(data, status, url){
				}, $http
			);	
						
			$mdDialog.cancel();
        }
    };
}
