// file: scorecards-controller.js

(function() {
    'use strict';

    angular.module('traxion')
        .controller('ScorecardsController', ['$cookies', '$rootScope', '$q', '$timeout', '$http', '$log', '$mdDialog',
            function($cookies, $rootScope, $q, $timeout, $http, $log, $mdDialog) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                // controller variables ========================================
                self.interval = 'ALL';
                self.scorecardIntervals = ['ALL', 'Weekly', 'Monthly'];
                self.teamMembers = angular.fromJson($cookies.get('teamMembers'));

				// Load issues from the API
				dbService($rootScope, $http, $timeout);

				ensureWeeks($rootScope, $http, $cookies);
				ensureMonths($rootScope, $http, $cookies);

				reloadScorecardFromDb(self, $rootScope, $http, $log, $cookies, 'Monthly');
				reloadScorecardFromDb(self, $rootScope, $http, $log, $cookies, 'Weekly');

				self.saveMeasurable = function(rowData, columnSchema, newValue, interval) {
					// Update Local Model
					rowData[columnSchema.OrdinalPosition] = newValue;

					var scorecardVariableID = null;

					switch (interval)
					{
						case 'Weekly':
							scorecardVariableID = $rootScope.dbReadRowFieldValue("ScorecardVariableID", rowData, self.weeklyGrid.FieldSchemaList);
							break;

						default:
							scorecardVariableID = $rootScope.dbReadRowFieldValue("ScorecardVariableID", rowData, self.monthlyGrid.FieldSchemaList);
							break;
					}

					$rootScope.dbSaveFieldDirect($cookies.get("ModuleID"), 'ScorecardVariable',
    										'ScorecardVariableID',
    										scorecardVariableID,
    										columnSchema.ID,
    										newValue,
    										function() {
    											// Do nothing on sucess
    										},
    										function() {
    											// Show error
    										},
    										$http);
				};

				// Save Scorecard Variable / Value
				self.saveVariable = function(rowData, columnSchema, newValue, interval) {

					// Update Local Model
					rowData[columnSchema.OrdinalPosition] = newValue;

					// Everything else updates the API data.
					var scorecardIntervalName = columnSchema.ID;
					var scorecardVariableID = null;

					switch (interval)
					{
						case 'Weekly':
							scorecardVariableID = $rootScope.dbReadRowFieldValue("ScorecardVariableID", rowData, self.weeklyGrid.FieldSchemaList);
							replaceIntervalWithAverage($rootScope, self.weeklyGrid);
							break;

						default:
							scorecardVariableID = $rootScope.dbReadRowFieldValue("ScorecardVariableID", rowData, self.monthlyGrid.FieldSchemaList);
							replaceIntervalWithAverage($rootScope, self.monthlyGrid);
							break;
					}

					// find the ScorecardIntervalID from the ReferenceDate
					$rootScope.dbSearchDirectFiltered($cookies.get("ModuleID"), 'ScorecardInterval',
    					'ReferenceDate', scorecardIntervalName,
    					1, 1,
    					function(data) {
    						// Success
    						var scorecardIntervalID = $rootScope.dbReadRowFieldValue("ScorecardIntervalID", data.RowList[0], data.FieldSchemaList);

    						$rootScope.dbSearchDirectFilteredAnd($cookies.get("ModuleID"), 'Scorecard',
    							'ScorecardVariableID', scorecardVariableID,
    							'ScorecardIntervalID', scorecardIntervalID,
    							1, 1,
    							function(data) {
    								if (data.RowList.length > 0) {
    									// Update an existing scorecard value
    									var scorecardID = $rootScope.dbReadRowFieldValue("ScorecardID", data.RowList[0], data.FieldSchemaList);

    									$rootScope.dbSaveFieldDirect($cookies.get("ModuleID"), 'Scorecard',
    										data.PrimaryFieldID,
    										scorecardID,
    										'Value',
    										newValue,
    										function() {
    											// Do nothing on sucess
    										},
    										function() {
    											// Show error
    										},
    										$http);
    								} else {
    									// Create a new scorecard value
    									$rootScope.dbNewDirect($cookies.get("ModuleID"), 'Scorecard',
    										function(data) {
    											$rootScope.dbWriteFieldValue("ScorecardVariableID", data.FieldGroupList, scorecardVariableID);
    											$rootScope.dbWriteFieldValue("ScorecardIntervalID", data.FieldGroupList, scorecardIntervalID);
    											$rootScope.dbWriteFieldValue("Value", data.FieldGroupList, newValue);
    											$rootScope.dbSaveDirect($cookies.get("ModuleID"), 'Scorecard', data,
    												function() { },
    												function() { },
    												$http);
    										},
    										function(data, status, url){},
    										$http);
    								}
    							},
    							function() {

    							},
    							$http);
    					},
    					function() {
    						// Show error
    					},
						$http);
				};

				// We use this to ensure the new scorecard item exists before we reload.
				$rootScope.reloadScorecard = function() {
					reloadScorecardFromDb(self, $rootScope, $http, $log, $cookies, 'Monthly');
					reloadScorecardFromDb(self, $rootScope, $http, $log, $cookies, 'Weekly');
				};

                self.addItem = function(ev) {
                    $mdDialog.show({
                        controller: AddScorecardController,
                        controllerAs: 'addScoreCtrl',
                        templateUrl: '../views/add-scorecard.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function(answer) {

                    }, function() {
                        // Reload happens from the add-scorecard controller.
                    });
                };

                self.deleteMeasurable = function(rowData, columnSchema, interval, ev) {
                    var confirm = $mdDialog.confirm()
                        .title('Would you like to delete this measurable?')
                        .content('This action is irreversible.')
                        .ariaLabel('Delete')
                        .targetEvent(ev)
                        .ok('Yes')
                        .cancel('No');

                    $mdDialog.show(confirm).then(function() {

						var scorecardVariableID = null;

						switch (interval)
						{
							case 'Weekly':
								scorecardVariableID = $rootScope.dbReadRowFieldValue("ScorecardVariableID", rowData, self.weeklyGrid.FieldSchemaList);
								break;

							default:
								scorecardVariableID = $rootScope.dbReadRowFieldValue("ScorecardVariableID", rowData, self.monthlyGrid.FieldSchemaList);
								break;
						}

                         $rootScope.dbDeleteDirect($cookies.get("ModuleID"), 'ScorecardVariable',
														scorecardVariableID,
														function() {
															reloadScorecardFromDb(self, $rootScope, $http, $log, $cookies, 'Monthly');
															reloadScorecardFromDb(self, $rootScope, $http, $log, $cookies, 'Weekly');
														},
														function() {
															// Show error
														}
														, $http);
                    }, function() {
                        // TODO: need keep function here
                        console.log('Keep');
                    });
                };

            }]);
})();

function reloadScorecardFromDb(self, $rootScope, $http, $log, $cookies, interval) {

	$rootScope.dbSearchDirectCustomDataPipeline($cookies.get("ModuleID"), 'Scorecard', 'ScorecardPivotTable' + interval,
												function(data) {
													reloadScorecardGrid(self, $rootScope, $log, data, interval);
												},
												function() {
													// show error
												},
												$http);
}

function reloadScorecardGrid(self, $rootScope, $log, data, interval) {

	switch (interval)
	{
		case 'Weekly':
			self.weeklyGrid = replaceIntervalWithAverage($rootScope, data);
			break;

		default:
			self.monthlyGrid = replaceIntervalWithAverage($rootScope, data);
			break;
	}

}

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

// Switch out the interval column with an average column.
function replaceIntervalWithAverage($rootScope, data) {

	// Change the column name from Interval to Average
	angular.forEach(data.FieldSchemaList, function (fieldSchema, key) {
		if (fieldSchema.DisplayName == 'Interval') {
			fieldSchema.DisplayName = 'Average';
		}
	});

	// Calculate the average.
	angular.forEach(data.RowList, function (row, key) {
		// Loop through each field.
		var total = 0;
		var intervals = 0;
		var reading = false;
		var type = '';

		angular.forEach(data.FieldSchemaList, function (fieldSchema, key) {
			if (reading) {
				var cellValue = $rootScope.dbReadRowFieldValue(fieldSchema.ID, row, data.FieldSchemaList);

				cellValue = cellValue + "";
				cellValue = cellValue.replace('$', '').replace('%', '');

				if (isNumeric(cellValue)) {
					total = total + parseFloat(cellValue);
					intervals++;

					// Format for type
					switch (type)
					{
						case "Currency":
							$rootScope.dbWriteRowFieldValue(fieldSchema.ID, row, data.FieldSchemaList, "$" + cellValue);
							break;

						case "Percent":
							$rootScope.dbWriteRowFieldValue(fieldSchema.ID, row, data.FieldSchemaList, cellValue + "%");
							break;
					}
				}
			} else if (fieldSchema.ID == 'Type') {
				reading = true;
				type = $rootScope.dbReadRowFieldValue("Type", row, data.FieldSchemaList);
				fieldSchema.Hidden = true;
			}
		});

		if ((total > 0) && (intervals > 0)) {
			var average = total / intervals;
			$rootScope.dbWriteRowFieldValue('Interval', row, data.FieldSchemaList, average);
		} else {
			$rootScope.dbWriteRowFieldValue('Interval', row, data.FieldSchemaList, '');
		}
	});

	return data;
}

// Ensure that 13 weeks exist.
function ensureWeeks($rootScope, $http, $cookies) {

	// Search the list of weeks from the API
	$rootScope.dbSearchDirectFiltered($cookies.get("ModuleID"), 'ScorecardInterval',
			'Interval', 'Weekly',
			100, 1,
			function(data) {
				// Build a list of weeks within js
				var totalIntervals = 13;
				var daysInWeek = 7;
				//var totalDays = totalIntervals * daysInWeek;

				for (interval = totalIntervals; interval >= 1;  interval--) {
					if (ensureWeek($rootScope, $cookies, $http, (interval * daysInWeek), data)) {
						// We only want to add one week at a time.
						break;
					}
				}
			},
			function() {
				// Do nothing on error
			},
			$http);
}

// Ensure that 13 weeks exist.
function ensureMonths($rootScope, $http, $cookies) {

	// Search the list of weeks from the API
	$rootScope.dbSearchDirectFiltered($cookies.get("ModuleID"), 'ScorecardInterval',
		'Interval', 'Monthly',
		100, 1,
		function(data) {
			// Build a list of weeks within js
			var totalIntervals = 13;

			for (interval = totalIntervals; interval >= 1;  interval--) {
				if (ensureMonth($rootScope, $cookies, $http, interval, data)) {
					// We only want to add one week at a time.
					break;
				}
			}
		},
		function() {
			// Do nothing on error
		},
		$http);
}

function ensureWeek($rootScope, $cookies, $http, day, data) {

	var weekAdded = false;

	var refDate = new Date();
	refDate.setDate(refDate.getDate()-(day));

	var endOfWeek = getEndOfWeek(refDate);

	var referenceDateFormat = gerReferenceDate(endOfWeek);
	var userDisplayFormat = getUserDisplayFormat(endOfWeek);

	if (intervalExists($rootScope, referenceDateFormat, userDisplayFormat, data) == false) {
		weekAdded = true;
		createNewScorecardInterval($rootScope, $cookies, $http, userDisplayFormat, 'Weekly', referenceDateFormat);
	}

	return weekAdded;

}

function createNewScorecardInterval($rootScope, $cookies, $http, userDisplayFormat, interval, referenceDateFormat) {

	// Pull a new row def from the API
	$rootScope.dbNewDirect($cookies.get("ModuleID"), 'ScorecardInterval',
		function(newIntervalData) {
			// Fill in all new interval data
			$rootScope.dbWriteFieldValue("IntervalName", newIntervalData.FieldGroupList, userDisplayFormat);
			$rootScope.dbWriteFieldValue("Interval", newIntervalData.FieldGroupList, interval);
			$rootScope.dbWriteFieldValue("ReferenceDate", newIntervalData.FieldGroupList, referenceDateFormat);
			// Create a new interval in the database.
			$rootScope.dbSaveDirect($cookies.get("ModuleID"), 'ScorecardInterval', newIntervalData,
				function() {
					// We could reload the grid here if we wanted to.
				},
				function() {
					}, $http
				);
		},
		function(data, status, url){
		}, $http
	);
}

// Ensure that 13 months exist.
function ensureMonth($rootScope, $cookies, $http, month, data) {

	var monthAdded = false;

	var refDate = new Date();
	refDate.setMonth(refDate.getMonth()-(month));

	var referenceDateFormat = getReferenceMonth(refDate);
	var userDisplayFormat = getUserDisplayFormatMonth(refDate);

	if (intervalExists($rootScope, referenceDateFormat, userDisplayFormat, data) == false) {
		monthAdded = true;
		createNewScorecardInterval($rootScope, $cookies, $http, userDisplayFormat, 'Monthly', referenceDateFormat);
	}

	return monthAdded;

}

function getEndOfWeek(date) {

	var endOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6 - date.getDay());

	return endOfWeek;
}

// Formats a given date as a reference which should never change.
function gerReferenceDate(endOfWeek) {

	var referenceDateFormat = null;

	referenceDateFormat = new Date(endOfWeek), month = '' + (endOfWeek.getMonth() + 1), day = '' + endOfWeek.getDate(), year = endOfWeek.getFullYear();

    if (month.length < 2) {
		month = '0' + month;
	}

    if (day.length < 2) {
		day = '0' + day;
	}

	referenceDateFormat = [year, month, day].join('-');

    return referenceDateFormat;

}

function getReferenceMonth(dayInMonth) {

	var referenceDateFormat = null;

	referenceDateFormat = new Date(dayInMonth), month = '' + (dayInMonth.getMonth() + 1), day = '' + dayInMonth.getDate(), year = dayInMonth.getFullYear();

	if (month.length < 2) {
		month = '0' + month;
	}

	referenceDateFormat = [year, month].join('-');

    return referenceDateFormat;

}

function getUserDisplayFormat(endOfWeek) {

	var userDisplayFormat = endOfWeek.toDateString().split(" ")[1] + ' ' + endOfWeek.getDate();

	return userDisplayFormat;

}

function getUserDisplayFormatMonth(dayInMonth) {

	var userDisplayFormat = dayInMonth.toDateString().split(" ")[1];

	return userDisplayFormat;

}

function intervalExists($rootScope, referenceDateFormat, userDisplayFormat, data) {

	var found = false;
	var displayFormat = '';
	var scorecardIntervalId = '';

	angular.forEach(data.RowList, function (row, key) {
		var referenceDate = $rootScope.dbReadRowFieldValue("ReferenceDate", row, data.FieldSchemaList);

		if (referenceDate == referenceDateFormat) {
			displayFormat = $rootScope.dbReadRowFieldValue("IntervalName", row, data.FieldSchemaList);
			scorecardIntervalId = $rootScope.dbReadRowFieldValue(data.PrimaryFieldID, row, data.FieldSchemaList);
			found = true;
		}
	});

	//  Check the display format.
	if ((found) && (userDisplayFormat != displayFormat)) {
		$rootScope.dbSaveFieldDirect($cookies.get("ModuleID"), 'ScorecardInterval',
			data.PrimaryFieldID,
			scorecardID,
			'IntervalName',
			userDisplayFormat,
			function() {
				// Do nothing on sucess
			},
			function() {
				// Show error
			},
			$http);
	}

	return found;

}
