// file: add-todo-controller.js

function ViewCompaniesController ($cookies, $mdDialog, $rootScope, $http, $location) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.companies = angular.fromJson($cookies.get('companies'));

    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.loadCompany = function(company) {
        
		$rootScope.loadCompany($cookies, $rootScope, $http, company.id);
                    
		$mdDialog.cancel();	
					
		$location.path('/dashboard');				
    };
}
