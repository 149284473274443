// file: toolbar-controller.js

(function() {

    'use strict';

    angular.module('traxion')
        .controller('ToolbarController', ['$http', '$cookies', '$mdSidenav', '$mdDialog', '$rootScope', '$location',
            function($http, $cookies, $mdSidenav, $mdDialog, $rootScope, $location) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                // controller variables ========================================
                self.title = root.title;

                self.companies = angular.fromJson($cookies.get('companies'));

                // controller functions ========================================
                self.openSidebar = function() {
                    $mdSidenav('left').open();
                };

                self.viewAllCompanies = function(ev) {
                    $mdDialog.show({
                        controller: ViewCompaniesController,
                        controllerAs: 'viewCompCtrl',
                        templateUrl: '../views/view-companies.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function(answer) {
                        // $scope.status = 'You said the information was "' + answer + '".';
                    }, function() {
                        // $scope.status = 'You cancelled the dialog.';
                    });
                };
				
				self.viewCompany = function(company) {

					$rootScope.loadCompany($cookies, $rootScope, $http, company.id);
                    
					$mdDialog.cancel();	
					
					$location.path('/dashboard');				
				};

            }]);

})();
