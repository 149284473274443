// file: rocks-controller.js
var inRocksReload = true;

(function() {
    'use strict';

    angular.module('traxion')
        .controller('RocksController', ['$cookies', '$rootScope', '$timeout', '$http', '$mdDialog',
            function($cookies, $rootScope, $timeout, $http, $mdDialog) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                // controller variables ========================================
                self.owner = $cookies.get('currentUser');
                self.rockOwners = angular.fromJson($cookies.get('teamMembers'));

                self.statuses = [
                    {
                        label: OFF_TRACK,
                        value: 0
                    },
                    {
                        label: ON_TRACK,
                        value: 1
                    },
                    {
                        label: COMPLETE,
                        value: 2
                    }
                ];

                self.columns = [
                    {
                        name: 'Rock',
                        hide: false
                    },
                    {
                        name: 'Status',
                        hide: false
                    },
                    {
                        name: 'Owner',
                        hide: true
                    },
                    {
                        name: 'Due By',
                        hide: true
                    }
                ];

				// Load issues from the API
				dbService($rootScope, $http, $timeout);

				reloadRocksFromDb(self, $rootScope, $http, $cookies, $timeout);

                self.saveField = function(rock, primaryFieldID, primaryFieldValue, fieldIdToUpdate, newValue) {
					if (inRocksReload == false) {
                        $rootScope.dbSaveFieldDirect($cookies.get("ModuleID"), 'Rock',
							primaryFieldID,
							primaryFieldValue,
							fieldIdToUpdate,
							newValue,
							function() {
								// Do nothing on sucess
							},
							function() {
								// Show error
							}
							, $http);

                        switch (fieldIdToUpdate)
						{
							case 'Status':
								switch (newValue)
								{
									case '0':
										rock.complete = false;
										rock.status.label = OFF_TRACK;
										break;

									case '1':
										rock.complete = false;
										rock.status.label = ON_TRACK;
										break;

									case '2':
										rock.status.label = COMPLETE;
										rock.complete = true;
										break;
								}
								break;
						}
					}
				};

				self.saveRock = function(rock, fieldIdToUpdate, newValue) {
					self.saveField(rock, rock.pkfieldid, rock.pkfieldval, fieldIdToUpdate, newValue);
				}

				self.onSelectChange = function(rock) {

					if (rock.complete) {
						rock.status.value = 2;
						rock.status.label = COMPLETE;
					}
					else {
						rock.status.value = 0;
						rock.status.label = OFF_TRACK;
					}

					self.saveRock(rock, 'Status', rock.status.value);

                };

                self.rocksOwnerFilter = function(rock) {
                    return rock.owner === self.owner;
                };

                self.addRock = function(ev) {
                    $mdDialog.show({
                        controller: AddRockController,
                        controllerAs: 'addRockCtrl',
                        templateUrl: '../views/add-rock.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function(answer) {
                        // $scope.status = 'You said the information was "' + answer + '".';
                    }, function() {
                        reloadRocksFromDb(self, $rootScope, $http, $cookies, $timeout);
                    });
                };

                self.deleteRock = function(rock, ev) {
                    var confirm = $mdDialog.confirm()
                        .title('Would you like to delete this rock?')
                        .content('This action is irreversible.')
                        .ariaLabel('Delete')
                        .targetEvent(ev)
                        .ok('Yes')
                        .cancel('No');

                    $mdDialog.show(confirm).then(function() {
                        $rootScope.dbDeleteDirect($cookies.get("ModuleID"), 'Rock',
														rock.pkfieldval,
														function() {
															reloadRocksFromDb(self, $rootScope, $http, $cookies, $timeout);
														},
														function() {
															// Show error
														}
														, $http);
                    }, function() {
                        // TODO: need keep function here
                        console.log('Keep');
                    });
                };

            }]);
})();

function reloadRocksFromDb(self, $rootScope, $http, $cookies, $timeout) {

	inRocksReload = true;

	$rootScope.dbSearchDirect($cookies.get("ModuleID"), 'Rock', 100, 1,
											function(data) {
												reloadRockGrid(self, $rootScope, data, $timeout);
											},
											function() {
												// show error
											},
											$http);
}

function reloadRockGrid(self, $rootScope, data, $timeout) {

	// Convert data into issues
	var gridJson = '{"count": "0","data": []}';

	var gridData = angular.fromJson(gridJson);
	gridData.count = data.TotalRows;

	// foreach row
	angular.forEach(data.RowList, function (row, key) {
		var primaryFieldID = data.PrimaryFieldID;
		var primaryFieldValue = $rootScope.dbReadRowFieldValue(data.PrimaryFieldID, row, data.FieldSchemaList);
		var displayName = $rootScope.dbReadRowFieldValue("Name", row, data.FieldSchemaList);
		var rowStatusValue = $rootScope.dbReadRowFieldValue("Status", row, data.FieldSchemaList);
		var rowTimeFrame = Date.parse($rootScope.dbReadRowFieldValue("DueDate", row, data.FieldSchemaList));
		var rowOwner = $rootScope.dbReadRowFieldValue("AssignedTo", row, data.FieldSchemaList);

        var rowJson = '{"pkfieldid":"","pkfieldval":"","rock": "This is a simple rock","status": {"label": "Off-Target","value": 0},"owner": ""}';

		var rowData = angular.fromJson(rowJson);

		rowData.pkfieldid = primaryFieldID;
		rowData.pkfieldval = primaryFieldValue;
		rowData.rock = displayName;
        rowData.status.value = rowStatusValue;
        rowData.complete = (rowStatusValue == 2);
        rowData.owner = rowOwner;

		switch (rowStatusValue)
		{
			case '0':
				rowData.status.label = OFF_TRACK;
                rowData.status.value = parseInt(0);
				break;

			case '1':
				rowData.status.label = ON_TRACK;
                rowData.status.value = parseInt(1);
				break;

			case '2':
				rowData.status.label = COMPLETE;
                rowData.status.value = parseInt(2);
				break;

			default:
				rowData.status.label = OFF_TRACK;
                rowData.status.value = parseInt(0);
				break;
		}

        if (rowTimeFrame) {
            rowData.dueBy = new Date(rowTimeFrame);
        }
        else {
            rowData.dueBy = new Date(+new Date + 604800000);
        }

		gridData.data.push(rowData);

	});

	self.rocks = gridData;

    $timeout(function() {inRocksReload = false;}, 700);
}
