// file: four0four-controller.js

(function() {

    'use strict';

    angular.module('traxion')
        .controller('Four0FourController', ['$rootScope',
            function($rootScope) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                // controller variables ========================================

            }]);

})();
