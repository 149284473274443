// file: four0four-controller.js

(function() {

    'use strict';

    angular.module('traxion')
        .controller('SettingsController', ['$http', '$mdDialog', '$rootScope',
            function($http, $mdDialog, $rootScope) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

				self.user = {};
				
				self.readUserSettings = function() {
					// Read the account information.
					$rootScope.dbReadDirect('master', 'sysLogin', '{sysLoginID}',
					function(userSettingsData) {						
						self.user.firstName = $rootScope.dbReadFieldValue('FirstName', userSettingsData.FieldGroupList);
						self.user.lastName = $rootScope.dbReadFieldValue('LastName', userSettingsData.FieldGroupList);
						self.user.email = $rootScope.dbReadFieldValue('Username', userSettingsData.FieldGroupList);
						//self.user.password = {};
						//self.user.password.value = $rootScope.dbReadFieldValue('Password', userSettingsData.FieldGroupList);
						//self.user.password.sensitiveValue = self.user.password.value;
					},
					function () {
					}, $http);
				};
				
				self.readUserSettings();
				
                // controller variables ========================================                
                self.user.password= 'abc1234';
                self.user.defaultCompany = 'Syrup';

                // controller functions ========================================
                self.deleteAccount = function(ev) {
                    var confirm = $mdDialog.confirm()
                        .title('Would you like to delete this account?')
                        .content('This action is irreversible.')
                        .ariaLabel('Delete')
                        .targetEvent(ev)
                        .ok('Yes')
                        .cancel('No');

                    $mdDialog.show(confirm).then(function() {
                        // TODO: need Delete and logout function here
                        console.log('Delete');
                    }, function() {
                        // TODO: need keep function here
                        console.log('Keep');
                    });
                };

                self.editName = function(ev) {
                    $mdDialog.show({
                        controller: EditAccountController,
                        controllerAs: 'editAccountCtrl',
                        templateUrl: '../views/edit-name.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        self.readUserSettings();
                    }, function() {
                        //
                    });
                };

                self.editEmail = function(ev) {
                    $mdDialog.show({
                        controller: EditAccountController,
                        controllerAs: 'editAccountCtrl',
                        templateUrl: '../views/edit-email.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        self.readUserSettings();
                    }, function() {
                        // cancelled dialog
                    });
                };

                self.editPassword = function(ev) {
                    $mdDialog.show({
                        controller: EditAccountController,
                        controllerAs: 'editAccountCtrl',
                        templateUrl: '../views/edit-password.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        self.readUserSettings();
                    }, function() {
                        // cancelled dialog
                    });
                };

                self.editDefaultCompany = function(ev) {
                    $mdDialog.show({
                        controller: EditAccountController,
                        controllerAs: 'editAccountCtrl',
                        templateUrl: '../views/edit-default-company.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        // close dialog
                    }, function() {
                        // cancelled dialog
                    });
                };

                self.editDefaultTeams = function(ev) {
                    $mdDialog.show({
                        controller: EditAccountController,
                        controllerAs: 'editAccountCtrl',
                        templateUrl: '../views/edit-default-teams.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        // close dialog
                    }, function() {
                        // cancelled dialog
                    });
                };

            }]);

})();
