// scorecard-select-directive.js

(function() {
    'use strict';

    angular.module('traxion')
        .directive('scorecardSelect', [
            function() {
                return {
                    restrict: 'AE',
                    link: function (scope, element, attrs) {
                        element.bind('blur', function (event) {
                            var owner = element.parent().find('[owner-display]');
                            owner.css('display', 'block');

                            element.css('display', 'none');
                        });
                    }
                }
            }])
        .directive('scorecardOwner', [
            function() {
                return {
                    restrict: 'AE',
                    link: function (scope, element, attrs) {
                        element.bind('click', function (event) {
                            var owner = element.find('[owner-display]');
                            owner.css('display', 'none');

                            var select = element.find('[scorecard-select]');
                            select.css('display', 'block');
                        });
                    }
                }
            }]);
})();
